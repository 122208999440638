import React from 'react'
import { useMemo } from 'react'
import './Maps.css'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
const { KEY } = process.env

function Maps() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA9yKXiVOr1nhz0BgYaI84jwkNJrhIXy8M"
  })

  if (!isLoaded) return <div>Loading...</div>

  return (
    <div className='ubicacion'>
      <Map />
    </div>
  )
}

function Map() {
  const center = useMemo(() => ({ lat: -32.946099, lng: -68.829347 }), [])

  return (

    <GoogleMap
      zoom={10}
      center={center} mapContainerClassName='map-container'
    >
      <Marker position={center} />
    </GoogleMap>)
}

export default Maps